import React from "react";
import DividerWaveSection from "./DividerWaveSection";
import Video from "../Assets/images/video-home.webm";
import VideoMockup from "./VideoMockup";
import dashboard from "Assets/images/aplicativo-dashboard.png";
import sofa from "Assets/images/sofa-presik2.webp";

const PageHeroStandard = ({
  children,
  backgroundHero = "bgAnimateBlue",
  colorHero = "text-white",
  divide = "gray",
  style = false,
  image = false,
  mode = false,
  roundedBottom = false,
  
}) => {
  const baseStyle =  style || "pt-16 pb-0 px-4 lg:px-2 xl:px-0";
  if (backgroundHero === "bgAnimateBlue") {
    return (
      <>
        <section className={`bg-gray-presik text-white ${baseStyle}`}>
          {children}
        </section>
        {divide && <DividerWaveSection reverse={true} colorWabe={divide} />}
      </>
    );
  }
  if (backgroundHero === "bgAnimateBLueRoun") {
    return (
      <>
        <section className={`bg-bluePresik text-white ${baseStyle} `}>
          {children}
        </section>
        {divide && <DividerWaveSection reverse={true} />}
      </>
    );
  }
  if (backgroundHero === "bgBlack") {
    return (
      <section
        className={`text-gray-600 bg-cover pb-24 px-5 md:px-0 ${baseStyle} ${backgroundHero}`}
      >
        {children}
      </section>
    );
  }

  if (backgroundHero === "image") {
    return (
      <section
        className={`w-full md:h-fit bg-bluePresik-tecno relative overflow-hidden mb-20   ${baseStyle} ${backgroundHero}`}
      >
        <div className="container mx-auto px-5 md:px-0 grid md:grid-cols-2 h-full">
          <div className="w-full flex flex-col items-start justify-start md:pt-20 relative z-20 ">
            <main className="text-white text-left">
              <div>{children}</div>
            </main>
            <VideoMockup video={Video} mode="video" image={image} />
          </div>
          <div className="w-full flex flex-col items-end justify-end relative z-10">
            <img
              src={sofa}
              className="absolute  hidden md:block bottom-0  z-10 max-w-[1200px] w-[1200px] right-[-38vw]"
            />

            <img
              src={image}
              className="w-3/3 relative right-56 z-30 hidden md:block"
            />
          </div>

          {/* formas  */}

          <div
            className="h-96 w-96 bg-gradient-to-tr from-yellow-200 to-yellow-300 absolute -left-10 -bottom-52 hidden md:block"
            style={{ borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%" }}
            data-aos="fade-up"
            data-aos-duration="2500"
          ></div>
        </div>

        <div className="w-1/3 h-full bg-gradient-to-t bgTecholo absolute -right-10 top-0 hidden md:block"></div>

        <div className="w-screen h-14 bgWaveWhite bg-cover z-40 absolute bottom-0 bg-no-repeat hidden md:block"></div>
      </section>
    );
  }

  if (backgroundHero === "heroHomeDividerLine") {
    return (
      <section
        className={`w-full md:h-fit bg-bluePresik-tecno relative overflow-hidden  md:max-h-[70vh]  ${baseStyle} ${backgroundHero}`}
      >
        <div className="container mx-auto px-5 md:px-0 grid md:grid-cols-2 h-full">
          <div className="w-full flex flex-col items-start justify-start md:pt-10 relative z-20 ">
            <main className="text-white text-left">
              <div>{children}</div>
            </main>
          </div>
          <div className="w-full flex flex-col items-end justify-end relative z-10">
            <img
              src={image}
              className="md:w-[416px] w-[205px] relative md:right-56 z-30 md:top-20 top-3 mx-auto md:mx-0"
            />
      
          </div>

          {/* formas  */}

          <div
            className="h-96 w-96 bg-gradient-to-tr from-yellow-200 to-yellow-300 absolute -left-10 -bottom-60 hidden md:block"
            style={{ borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%" }}
            data-aos="fade-up"
            data-aos-duration="2500"
          ></div>
        </div>

        <div className="w-full transform rotate-45 h-full bg-gradient-to-t bg-cover bgTecholo  absolute -right-[600px] top-0 hidden md:block"></div>

        <div className="w-screen h-14 bgWaveWhite bg-cover z-40 absolute bottom-0 bg-no-repeat hidden md:block"></div>
       
      </section>
    );
  }

  if (backgroundHero === "heroService") {
    return (
      <section
        className={`w-full bg-bluePresik relative overflow-hidden z-40 pt-10 md:h-[63vh] h-[880px] md:top-9 ${baseStyle} ${backgroundHero}`}
      >
        <div className="container mx-auto px-5 md:px-0 grid md:grid-cols-2 h-full  md:mt-0">
          <div className="w-full md:flex md:flex-col items-start justify-start md:pt-10 relative z-20 ">
            <main className="text-left pb-20">
              <div>{children}</div>
            </main>
          </div>

          <div
            className="h-96 w-96 bg-gradient-to-tr from-yellow-200 to-yellow-300 absolute -left-10 -bottom-60 hidden md:block"
            style={{ borderRadius: "30% 70% 70% 30% / 30% 30% 70% 70%" }}
            data-aos="fade-up"
            data-aos-duration="2500"
          ></div>
        </div>

        {/* <div className="w-full transform rotate-45 h-full bg-gradient-to-t bg-cover bgTecholo  absolute -right-[600px] top-0 hidden md:block"></div> */}
        {divide && (
        <div className="w-screen h-14 bgWaveWhite bg-cover z-40 absolute bottom-0 bg-no-repeat hidden md:block"></div>

        )}

        <img
              src={image}
              className="md:h-[90%] right-0  absolute  z-30 md:top-10 -bottom-1 md:bottom-0 h-full mx-auto md:mx-0"
            />
      </section>
    );
  }

  if (backgroundHero === "imageRound") {
    backgroundHero = "#1a1f3d";
    return (
      <>
        <section
          className={`w-full h-auto  relative z-30 ${baseStyle} ${backgroundHero}`}
          style={{ backgroundColor: backgroundHero }}
        >
          <div className="container mx-auto px-5 md:px-0 grid grid-cols-2 ">
            <div className="w-full flex flex-col items-start justify-start pt-20 relative z-20 ">
              <main className="text-white ">
                <div>{children}</div>
              </main>
            </div>
            <div className="w-full z-10">
              <img
                src={image}
                className="absolute -top-20 right-0 z-30 max-w-6xl"
                style={{ "clip-path": "circle(61.6% at 66% 17%)" }}
              />
            </div>
          </div>
        </section>
        <DividerWaveSection colorWabe="blue" reverse={true} />
      </>
    );
  }

  if (backgroundHero === "imageRoundHeroText") {
    backgroundHero = "#FDC815";
    const style = {
      clipPath: "ellipse(72% 64% at 54% 35%)",
      backgroundImage: `url(${image})`,
    };
    return (
      <section
        className={`w-full max-h-max relative z-30 overflow-hidden h-[55vh] bg-right-top bg-cover  ${image ?? ".bg-bluePresik-tecno"}`}
        style={style}
      >
        <div className=" mx-auto px-5 md:px-0 z-10 relative bg-  container">
          <main className="text-white z-30 py-20">
            <div className="z-40">{children} </div>
          </main>
        </div>
        <div className="md:-top-96 -top-32 w-screen absolute h-screen bg-gradient-to-t from-bg-bluePresik/20 via-bg-bluePresik to-bg-bluePresik ">
          {/* <img
            src={image}
            className="top-0  w-screen h-[55vh] object-cover object-top 
            "
          /> */}
        </div>
      </section>
    );
  }

  if (backgroundHero === "heroBasic") {
  
    return (
      <section
        className={`w-full max-h-max relative z-30 overflow-hidden h-[55vh] bg-right-top bg-cover bg-yellow-300`}
      >
        <div className=" mx-auto px-5 md:px-0 z-10 relative container">
          <main className="text-white z-30 py-20">
            <div className="z-40">{children} </div>
          </main>
        </div>
     
      </section>
    );
  }
};

export default PageHeroStandard;
